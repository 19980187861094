<template>
  <div class="pricing-table-area" id="pricing">
    <div class="pricing-table-content-area">
      <div class="container-fluid container-fluid--cp-150">
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <div class="section-title text-center section-space--mb_20">
              <h2 class="font-weight--bold mb-15 mailnjoy wow move-up">
                {{ $t("components.pricingOneShot.promotionTitle") }}
              </h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-12">
            <div class="price-plan-wrap mt-30 section-space--mb_60">
              <div class="price-plan-title mb-30 wow move-up">
                <h3 class="heading mailnjoy">
                  {{ $t("components.pricingOneShot.title") }}
                  <b><i>
                    {{ $t("components.pricingOneShot.promotionSubTitle") }}
                  </i></b>
                </h3>
                <small>{{ $t("components.pricingOneShot.promotionSubText") }}</small>
              </div>
              <ul class="check-list price-plan-list mb-30 wow move-up">
                <li class="list-item" v-for="i in [1,2,3]">
                  {{ $t("components.pricingOneShot.advantage" + i) }}
                </li>
              </ul>
              <p v-html="$t('components.pricingOneShot.quote')"/>
            </div>
          </div>
          <div id="packs-container" class="col-lg-9 col-md-12">
            <div class="container">
              <b-tabs class="ht-tab-menu mailnjoy wow move-up" v-model="defaultActiveTab">
                <b-tab :title="$t('components.pricingOneShot.euroPricing')">
                  <PackDetails :packs="packsEuro" currency="eur"/>
                </b-tab>
                <b-tab :title="$t('components.pricingOneShot.dollarPricing')">
                  <PackDetails :packs="packsDollar" currency="usd"/>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PackDetails from './PackDetails.vue'
import getCurrency from "@/utils/getCurrency";
export default {
  name: 'PricingOneShot',
  components: {
    PackDetails
  },
  data() {
    return {
      defaultActiveTab: 1,
      packsEuro: [
        {
          title: '1000',
          image: require('../../assets/img/check/illustration/credits-small.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 4,
            htBeforeReduction: 4,
            ttc: 4.8,
            perThousandCredits: 4
          }
        },
        {
          title: '10000',
          image: require('../../assets/img/check/illustration/credits-small.png'),
          shortList: true,
          discount: 0,
          price: {
            ht: 20,
            htBeforeReduction: 20,
            ttc: 24,
            perThousandCredits: 2
          }
        },
        {
          title: '50000',
          image: require('../../assets/img/check/illustration/credits-small.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 60,
            htBeforeReduction: 60,
            ttc: 72,
            perThousandCredits: 1.2
          }
        },
        {
          title: '100000',
          image: require('../../assets/img/check/illustration/credits-medium.png'),
          shortList: true,
          discount: 0,
          price: {
            ht: 95,
            htBeforeReduction: 95,
            ttc: 114,
            perThousandCredits: 0.95
          }
        },
        {
          title: '250000',
          image: require('../../assets/img/check/illustration/credits-medium.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 160,
            htBeforeReduction: 160,
            ttc: 192,
            perThousandCredits: 0.64
          }
        },
        {
          title: '500000',
          image: require('../../assets/img/check/illustration/credits-medium.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 290,
            htBeforeReduction: 290,
            ttc: 348,
            perThousandCredits: 0.58
          }
        },
        {
          title: '1000000',
          image: require('../../assets/img/check/illustration/credits-large.png'),
          shortList: true,
          discount: 0,
          price: {
            ht: 450,
            htBeforeReduction: 450,
            ttc: 540,
            perThousandCredits: 0.45
          }
        },
        {
          title: '2000000',
          image: require('../../assets/img/check/illustration/credits-large.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 790,
            htBeforeReduction: 790,
            ttc: 948,
            perThousandCredits: 0.4
          }
        }
      ],
      packsDollar: [
        {
          title: '1000',
          image: require('../../assets/img/check/illustration/credits-small.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 6,
            htBeforeReduction: 6,
            ttc: 7.2,
            perThousandCredits: 6
          }
        },
        {
          title: '10000',
          image: require('../../assets/img/check/illustration/credits-small.png'),
          shortList: true,
          discount: 0,
          price: {
            ht: 26,
            htBeforeReduction: 26,
            ttc: 31.2,
            perThousandCredits: 2.6
          }
        },
        {
          title: '50000',
          image: require('../../assets/img/check/illustration/credits-small.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 76,
            htBeforeReduction: 76,
            ttc: 91.2,
            perThousandCredits: 1.52
          }
        },
        {
          title: '100000',
          image: require('../../assets/img/check/illustration/credits-medium.png'),
          shortList: true,
          discount: 0,
          price: {
            ht: 120,
            htBeforeReduction: 120,
            ttc: 144,
            perThousandCredits: 1.2
          }
        },
        {
          title: '250000',
          image: require('../../assets/img/check/illustration/credits-medium.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 200,
            htBeforeReduction: 200,
            ttc: 240,
            perThousandCredits: 0.8
          }
        },
        {
          title: '500000',
          image: require('../../assets/img/check/illustration/credits-medium.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 365,
            htBeforeReduction: 365,
            ttc: 438,
            perThousandCredits: 0.73
          }
        },
        {
          title: '1000000',
          image: require('../../assets/img/check/illustration/credits-large.png'),
          shortList: true,
          discount: 0,
          price: {
            ht: 565,
            htBeforeReduction: 565,
            ttc: 678,
            perThousandCredits: 0.57
          }
        },
        {
          title: '2000000',
          image: require('../../assets/img/check/illustration/credits-large.png'),
          shortList: false,
          discount: 0,
          price: {
            ht: 990,
            htBeforeReduction: 990,
            ttc: 1188,
            perThousandCredits: 0.5
          }
        }
      ]
    }
  },
  created () {
    this.defaultActiveTab = getCurrency() === 'eur' ? 0 : 1
  }
};
</script>
<style>
#pricing .price-plan-list .list-item {
  color: #7e7e7e;
}

#pricing .row.pricing-table-three {
  margin-right: 0;
  margin-left: 0
}

#packs-container, #packs-container .container {
  padding: 0;
}

#pricing .heading.mailnjoy {
  color: #95c11f;
}

#pricing .pricing-table__inner {
  background-color: #f8fbef;
  margin-right: 0;
  margin-left: 0;
}

#pricing .pricing-table__inner::before {
  background-image: linear-gradient(-180deg, #dedc00 0%, #95c11f 50%, #638015 100%);
}

#pricing .pricing-table-three .pricing-table__title {
  color: #dedc00;
  font-size: 1.8rem;
  margin-right: 15%;
  margin-left: 15%;
}

#pricing .pricing-table-three .pricing-table__inner:hover .pricing-table__title {
  color: #fefefe;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.pricing-table__head {
  display: flex;
  flex-wrap: wrap;
}

#pricing .pricing-table__head .pricing-table__title {
  flex-basis: 100%;
}

#pricing .pricing-table__head .pricing-table__image {
  margin-bottom: 8px;
  flex-basis: 100%;
  align-self: flex-end;
}

#pricing .pricing-table-three .pricing-table__body {
  align-self: flex-end;
}

#pricing .pricing-table-three .pricing-table__body p {
  color: #7e7e7e;
  margin-bottom: 0;
}

#pricing .pricing-table-three .pricing-table__inner:hover .pricing-table__body p,
#pricing .pricing-table-three .pricing-table__inner:hover .pricing-table__body h3 {
  color: white;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

#pricing .pricing-table__feature-mark::before {
  background-image: linear-gradient(60deg, #95c11f 0%, #638015 100%);
}

#pricing .pricing-table__feature-mark span {
  font-size: 1rem;
}

#pricing .pricing-table__body h3 {
  color: #95c11f;
  font-size: 3rem;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

#pricing .pricing-table__body small {
  font-size: 1rem !important;
}

#pricing .pricing-table__body i {
  color: #95c11f;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

#pricing .pricing-table__inner:hover .pricing-table__body i {
  color: #FFF;
}

.hover-style-link.hover-style-mailnjoy:after {
  background-color: #95c11f;
}

.hover-style-link.mailnjoy, .hover-style-link.mailnjoy:hover {
  color: #95c11f;
}

.struck {
  text-decoration: none;
  position: relative;
}

.struck::before {
  top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
  background: red; /*this is the color of the line*/
  opacity: .7;
  content: '';
  width: 110%;
  position: absolute;
  height: .1em;
  border-radius: .1em;
  left: -5%;
  white-space: nowrap;
  display: block;
  transform: rotate(-15deg);
}
</style>
