<template>
  <div id="security-body">
    <div class="container fluid">
      <div class="row">
        <div class="col-lg-7 order-1 order-lg-1">
          <div class="section-title text-left section-space--mb_20">
            <h2 class="font-weight--bold mb-15 mailnjoy wow move-up">
              {{ $t("components.securityPrice.title") }}
            </h2>
            <p class="section-text_left wow move-up font-italic font-weight-light" v-for="i in [1,2]">
              {{ $t("components.securityPrice.p" + i) }}
            </p>
          </div>
        </div>
        <div class="col-lg-5 order-2 order-lg-2">
          <div class="features_image-wrap section-space--mb_40">
            <div class="image text-center wow move-up">
              <img class="img-fluid" src="../../assets/img/check/illustration/security.png"
                   :alt="$t('components.securityPrice.part1.imgAlt')">
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 mb-25 wow move-up">
          <div class="ht-service-box--three mailnjoy">
            <div class="service-box-wrap">
              <div class="image__media">
                <img src="../../assets/img/check/illustration/escort.png" class="img-fluid" alt="Icon">
              </div>
              <div class="content">
                <h5 class="heading font-weight--light">
                  {{ $t("components.securityPrice.part1.title") }}
                </h5>
                <div class="service_text">
                  <ul class="check-list green-check-list section-space--mb_30 wow move-up">
                    <li v-for="i in [1,2,3,4]" class="list-item"
                        v-html="$t('components.securityPrice.part1.point'+i,['<b>','</b>'])">
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 mb-25 wow move-up">
          <div class="ht-service-box--three mailnjoy">
            <div class="service-box-wrap">
              <div class="content">
                <h5 class="heading font-weight--light">
                  {{ $t("components.securityPrice.part2.title") }}
                </h5>
                <div class="service_text">
                  <ul class="check-list green-check-list section-space--mb_30 wow move-up">
                    <li v-for="i in [1,2]" class="list-item"
                        v-html="$t('components.securityPrice.part2.point'+i,['<b>','</b>'])">
                    </li>
                  </ul>
                </div>
              </div>
              <div class="image__media">
                <img src="../../assets/img/check/illustration/protection.png" class="img-fluid"
                     :alt="$t('components.securityPrice.part2.imgAlt')">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
<style type="text/css">
#security-body {
  background-image: none;
  padding-top: 160px;
  width: 100%
}

.mailnjoy .content h5 {
  color: #95C11F;
}

.image__media img {
  width: 14em;
}
</style>