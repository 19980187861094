<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 my-5"/>
      <div class="col-12">
        <div class="section-title text-center wow move-up">
          <h3 class=" mailnjoy-grey font-weight--light mailnjoy">
            {{ $t("components.resultDetails.title") }}
          </h3>
          <span class="section-text wow move-up">
              {{ $t("components.resultDetails.subtitle") }}
          </span>
        </div>
      </div>
    </div>
    <div class="row justify-content-md-center mt-2">
      <div class="col-md-12 text-center my-auto wow move-up">
        <a :href="standard" target="_blank">
          <img :src="standard" id="actual-illustration" class="img-fluid"/>
        </a>
      </div>
      <div class="col-md-12 text-center wow move-up">
        <span class="font-italic">
          {{ $t("components.resultDetails.simpleValidation") }}
        </span>
      </div>
    </div>
    <div class="row justify-content-md-center mt-5">
      <div class="col-md-12 text-center my-auto wow move-up">
        <a :href="detailed" target="_blank">
          <img :src="detailed" id="actual-illustration" class="img-fluid"/>
        </a>
      </div>
      <div class="col-md-12 wow move-up text-center mt-3">
        <span class="font-italic">
          {{ $t("components.resultDetails.deepValidation") }}
        </span>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-1"/>
      <div class="col-lg-4">
        <div class="section-title">
          <h2 class="mailnjoy-green my-4 text-break wow move-up">
            {{ $t("components.resultDetails.csvEnriched.title") }}
          </h2>
          <h5 class="mb-3 wow move-up">
            {{ $t("components.resultDetails.csvEnriched.subtitle") }}
          </h5>
          <div class="wow move-up">
            {{ $t("components.resultDetails.csvEnriched.body") }}
          </div>
        </div>
      </div>
      <div class="col-lg-7 text-center my-auto wow move-up">
        <a :href="csvEnriched" target="_blank">
          <img :src="csvEnriched" id="actual-illustration" class="img-fluid"/>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ResultDetails',
  data() {
    return {
      en: {
        standardScreenshot: require("../../assets/img/check/illustration/simple-clean-en.png"),
        detailedScreenshot: require("../../assets/img/check/illustration/detaille-clean-en.png"),
      },
      fr: {
        standardScreenshot: require("../../assets/img/check/illustration/simple-clean.png"),
        detailedScreenshot: require("../../assets/img/check/illustration/detaille-clean.png"),
      },
      csvEnriched: require("../../assets/img/check/illustration/csv_enrichi.png")
    }
  },
  computed: {
    standard: function () {
      return this.$i18n.locale === 'fr' ? this.fr.standardScreenshot : this.en.standardScreenshot
    },
    detailed: function () {
      return this.$i18n.locale === 'fr' ? this.fr.detailedScreenshot : this.en.detailedScreenshot
    }
  }
}
</script>
<style>
.mailnjoy-green {
  color: #95c11f;
}
</style>
