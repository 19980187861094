<template>
  <div id="inscription-content">
    <div class="container fluid">
      <div class="row align-items-center">
        <div class="col-lg-6 text-center mt-auto mb-auto">
          <div class="features_image-wrap section-space--mb_40">
            <div class="image wow move-up">
              <img class="img-fluid align-middle" src="../../assets/img/check/illustration/love_from_check.png"
                   alt="Forêt">
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="section-title text-center section-space--mb_20">
            <h2 class="font-weight--bold mb-15 mailnjoy wow move-up">
              {{ $t('components.newInscriptionContent.thanks') }}
            </h2>
            <p class="section-text_left wow move-up font-weight-light">
              {{ $t('components.newInscriptionContent.line1') }}
            </p>
            <p class="section-text_left wow move-up font-weight-light"
               v-html="$t('components.newInscriptionContent.line2')"></p>
            <h5 class="section-text_left mailnjoy wow move-up font-weight-light section-space--mt_30">
              {{ $t('components.newInscriptionContent.line3') }}
            </h5>
            <div class="tab-button wow move-up mt-5">
              <router-link :to="'/'+$i18n.locale+'/'" class="ht-btn ht-btn-md mailnjoy-outline">
                {{ $t('components.newInscriptionContent.home') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InscriptionContent',
  data() {
    return {}
  }
}
</script>
<style type="text/css">
#inscription-content {
  background-image: none;
  padding-top: 160px;
  width: 100%
}
</style>
