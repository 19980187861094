<template>
  <div id="ecology-content">
    <div class="container fluid">
      <div class="row align-items-center">
        <div class="col-lg-7 order-1 order-lg-1">
          <div class="section-title text-left section-space--mb_20">
            <h2 class="font-weight--bold mb-15 mailnjoy wow move-up">
              {{ $t("components.greenContent.title") }}
            </h2>
            <p class="section-text_left wow move-up font-weight-light">
              {{ $t("components.greenContent.subtitle") }}
            </p>
            <h5 class="section-text_left mailnjoy wow move-up font-weight-light section-space--mt_30">
              {{ $t("components.greenContent.engagements") }} :
            </h5>
            <ul class="check-list green-check-list mt-3 wow move-up">
              <li class="list-item">
                <b>{{ $t("components.greenContent.part1.title") }}</b><br/>
                {{ $t("components.greenContent.part1.content1") }}<br/>
                {{ $t("components.greenContent.part1.content2") }}<br/>
                {{ $t("components.greenContent.part1.content3") }}
              </li>
              <li class="list-item">
                <b>{{ $t("components.greenContent.part2.title") }}</b><br/>
                {{ $t("components.greenContent.part2.content") }}
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-5 order-2 order-lg-2 text-center mt-auto mb-auto">
          <div class="features_image-wrap section-space--mb_40">
            <div class="image wow move-up">
              <img class="img-fluid align-middle" src="../../assets/img/check/illustration/forest.png" alt="Forêt">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GreenContent',
  data() {
    return {}
  }
}
</script>
<style type="text/css">
#ecology-content {
  background-image: none;
  padding-top: 160px;
  width: 100%
}
</style>
