<template>
  <div>
    <CurveShape>
      <template v-slot:bgcolor>
        <path style="fill: #f8fbef;
                    stroke-miterlimit:10;"
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>
    <div id="feature-check">
      <div class="section-title text-center section-space--mb_20">
        <h2 class="font-weight--light mb-15 wow move-up mailnjoy">
          {{ $t("components.featureCheck.title") }}
        </h2>
        <span class="section-text wow move-up">
                    {{ $t("components.featureCheck.subtitle") }} &#x1F60E;
                </span>
        <!--Une équipe française, infra en france, rgpd,-->
      </div>
      <div class="container-fluid">
        <div id="features" class="row equal justify-center">
          <div v-for="item in items" :key="item.id" class="col-lg-4 col-md-6 wow move-up mt-3 mb-3">
            <div class="service-grid h-100">
              <div class="ht-service-box--two text-center style-03 h-100">
                <router-link :to="'/'+$i18n.locale+'/'+item.destination" class="h-100">
                  <div class="image mb-20">
                    <img :src="item.icon" class="img-fluid img-mailnjoy" alt="feature-icon">
                  </div>
                  <div class="content">
                    <h6 class="heading">{{ item.heading }}</h6>
                    <div class="service_text" v-html="item.text"></div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CurveShape id="bottom-curve" class="curve-bottom">
      <template v-slot:bgcolor>
        <path style="fill: #f8fbef;
                    stroke-miterlimit:10;"
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>
  </div>
</template>

<script>
import CurveShape from '../CurveShape'

export default {
  name: 'StartUpFeatureTwo',
  components: {
    CurveShape
  },
  computed: {
    locale:  function(){
      return this.$i18n.locale
    }
  },
  watch: {
    locale: function() {
      this.generateFeatures();
    }
  },
  data() {
    return {
      items: []
    }
  },
  mounted() {
    this.generateFeatures()
  },
  methods: {
    generateFeatures() {
      this.items = [
        {
          icon: require('../../assets/img/check/icons/datacenter.png'),
          heading: this.$t('components.featureCheck.datacenter.title'),
          destination: 'security',
          text: this.$t('components.featureCheck.datacenter.content', ['<b>', '</b>'])
        },
        {
          icon: require('../../assets/img/check/icons/gdpr.png'),
          heading: this.$t('components.featureCheck.rgpd.title'),
          destination: 'security',
          text: this.$t('components.featureCheck.rgpd.content', ['<b>', '</b>'])
        },
        {
          icon: require('../../assets/img/check/icons/scientist.png'),
          heading: this.$t('components.featureCheck.team.title'),
          destination: 'team',
          text: this.$t('components.featureCheck.team.content', ['<b>', '</b>'])
        },
        {
          icon: require('../../assets/img/check/icons/api-developer.png'),
          heading: this.$t('components.featureCheck.api.title'),
          destination: 'developer',
          text: this.$t('components.featureCheck.api.content', ['<b>', '</b>'])
        },
        {
          icon: require('../../assets/img/check/icons/forest.png'),
          heading: this.$t('components.featureCheck.ecology.title'),
          destination: 'ecology',
          text: this.$t('components.featureCheck.ecology.content', ['<sub>', '</sub>', '<b>', '</b>'])
        }
      ]
    }
  }
}
</script>
<style type="text/css">
@media (max-width: 998px) {
  #features.row {
    margin-right: 0;
    margin-left: 0;
  }
}

#feature-check {
  background-color: #f8fbef !important;
  padding-top: 10px;
  padding-bottom: 30px;
}

#bottom-curve {
  margin-top: -2px;
  margin-bottom: 0 !important;
}

.img-mailnjoy {
  height: 125px;
}

.ht-service-box--two .content .heading {
  color: #95C11F;
}

.service-grid .ht-service-box--two > a {
  padding-bottom: 15px;
}

#features {
  padding-right: 2.5%;
  padding-left: 2.5%;
}

#smiley {
  width: 16px;
}

.justify-center {
  justify-content: center;
}
</style>
