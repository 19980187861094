<template>
  <div class="main-container">
    <router-view></router-view>
    <!-- Navbar section -->
    <Header />

    <History :style="bgImg"/>
    <MeetTheTeam />
    <!-- Footer -->
    <FooterMain FooterStyle="bg_drak-footer" />
  </div>
  
</template>
<script>  
  import Header from "../../components/check/HeaderCheck"
  import History from "../../components/check/TeamHistory"
  import MeetTheTeam from "../../components/check/TeamCheck"
  import FooterMain from "../../components/check/FooterHome"

  export default {
    name: "team",
    components: {
      Header,
      History,
      MeetTheTeam,
      FooterMain
    },
    data() {
      return {
        bgImg: {
          backgroundImage: `url(${require("../../assets/img/check/hero/shape.png")})`,
          backgroundRepeat: "no-repeat",
        },
      }
    },
    mounted (){
      //reset to top
      window.scrollTo(0,0);
    }
  }
</script>