<template>
  <div id="pricing-header">
    <div class="container fluid">
      <div class="row">
        <div class="col-lg-6 order-1 order-lg-1 d-flex align-items-center">
          <div class="section-title text-center section-space--mb_20">
            <h2 class="font-weight--bold mb-15 mailnjoy wow move-up">
              {{ $t("components.pricingIntro.title") }}
            </h2>
            <p class="section-text_left wow move-up font-weight-light mb-4">
              {{ $t("components.pricingIntro.validation") }}
            </p>
            <div class="tab-button wow move-up">
              <router-link :to="'/'+$i18n.locale+'/validation'" class="ht-btn ht-btn-md mailnjoy-outline">
                {{ $t("components.pricingIntro.validationLink") }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-6 order-2 order-lg-2">
          <div class="features_image-wrap">
            <div class="image text-center wow move-up">
              <img class="img-fluid" src="../../assets/img/check/illustration/pricing.png" alt="pricing">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PricingIntro',
  data() {
    return {}
  },
};
</script>
<style type="text/css">
#pricing-header {
  background-image: none;
  padding-top: 160px;
  width: 100%
}
</style>