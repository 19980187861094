import HomeCheck from '../pages/check/HomeCheck'
import SecurityCheck from '../pages/check/Security'
import TeamCheck from '../pages/check/Team'
import PricingCheck from '../pages/check/Pricing'
import ValidationDetail from '../pages/check/Validation'
import Developer from '../pages/check/Developer'
import Green from '../pages/check/Green'
import CGU from '../pages/check/CGU'
import DataTreatment from '../pages/check/DataTreatment'
import PostInscription from '../pages/check/PostInscription'
import Partners from '../pages/check/Partners.vue'
import Partner from '../pages/check/Partner.vue'

import i18n from '@/i18n'

export const routes = [
    {
        path: "/",
        redirect: i18n.locale
    },
    {
        path: "/:lang",
        component: {
            template: '<router-view></router-view>'
        },
        children: [
            {
                path: 'pricing',
                name: i18n.t('routes.routes.pricing'),
                component: PricingCheck,
            },
            {
                path: 'team',
                name: i18n.t('routes.routes.team'),
                component: TeamCheck
            },
            {
                path: 'security',
                name: i18n.t('routes.routes.security'),
                component: SecurityCheck
            },
            {
                path: 'validation',
                name: i18n.t('routes.routes.validation'),
                component: ValidationDetail
            },
            {
                path: 'developer',
                name: i18n.t('routes.routes.developer'),
                component: Developer
            },
            {
                path: 'ecology',
                name: i18n.t('routes.routes.ecology'),
                component: Green
            },
            {
                path: 'cgu',
                name: i18n.t('routes.routes.cgu'),
                component: CGU
            },
            {
                path: 'data-treatment',
                name: i18n.t('routes.routes.data-treatment'),
                component: DataTreatment
            },
            {
                path: 'thank-you',
                name: i18n.t('routes.routes.thank-you'),
                component: PostInscription
            },
            {
                path: '/',
                name: i18n.t('routes.routes.home'),
                component: HomeCheck,
            },
            {
                path: 'partners',
                name: i18n.t('routes.routes.partners'),
                component: Partners,
            },
            {
                path: 'partner/:partner',
                name: i18n.t('routes.routes.partner'),
                component: Partner,
            }
        ]
    }
];