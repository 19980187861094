<template>
  <div class="footer-area-wrapper footer-mailnjoy" :class="FooterStyle">
    <div class="footer-copyright-area border-top section-space--ptb_30">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 col-sm-6 col-xl-4 text-left">
            <span class="copyright-text"
                  v-html="$t('components.footerHome.copyright',[new Date().getFullYear(),'<i class=\'fa fa-heart\'></i>'])"></span>
          </div>
          <div class="col-12 col-md-6 col-xl-1 text-xl-center surline">
            <router-link :to="'/'+$i18n.locale+'/CGU'" class="h-100">
              {{ $t('components.footerHome.cgu') }}
            </router-link>
          </div>
          <div class="col-12 col-md-6 col-xl-3 text-xl-center surline">
            <router-link :to="'/'+$i18n.locale+'/data-treatment'" class="h-100">
              {{ $t('components.footerHome.data') }}
            </router-link>
          </div>
          <div class="col-12 col-md-6 col-xl-2 text-xl-center surline">
            <router-link :to="'/'+$i18n.locale+'/partners'" class="h-100">
              {{ $t('components.footerHome.partners') }}
            </router-link>
          </div>
          <div class="col-12 col-md-6 col-xl-2 text-xl-center surline">
            {{ $t('components.footerHome.contact') }}: <a href="mailto:contact@mailnjoy.com">contact@mailnjoy.com</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterMain',
  props: ["FooterStyle"],
  data() {
    return {}
  }
}
</script>
<style type="text/css">
.footer-mailnjoy {
  background: #FFF;
  border-top: 0px solid #CCC;
}

.footer-mailnjoy .border-top {
  border-top: 0 !important;
}

.footer-mailnjoy .copyright-text {
  color: #7E7E7E;
}

.surline a:hover {
  text-decoration: underline;
  color: #555;
}

.fa.fa-heart {
  color: #b30000;
}

.icon-mailnjoy {
  width: 21px;
  margin-top: -2px;
}
</style>