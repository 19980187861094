import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import i18n from '@/i18n'
import WOW from 'wow.js/dist/wow.js';
import { VuePlausible } from 'vue-plausible'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(BootstrapVue)

import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/assets/css/fontawesome-all.min.css'
import 'animate.css/animate.min.css'
import '../src/assets/scss/style.scss'
import 'slick-carousel/slick/slick.css'

Vue.config.productionTip = false
Vue.config.productionSourceMap = false

import { routes } from './router/index'
import supportedLanguages from "@/i18n/supportedLanguages";

const router = new VueRouter({
    routes,
    mode: 'history',
    base: '/check',
    scrollBehavior (to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            }
        }
    }
})

// https://stackoverflow.com/questions/45091380/vue-router-keep-query-parameter-and-use-same-view-for-children
function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

// conservation du param origin pour le traçage des nouveaux inscrits
const languagesPaths = supportedLanguages.map((lang) => "/" + lang)
router.beforeEach((to, from, next) => {
    // corriger la langue
    let lang = to.params.lang
    if(lang === undefined) {
        lang = window.navigator.language.substring(0, 2)
    }
    if(!supportedLanguages.includes(lang)) {
        i18n.locale = 'en'
        next('en')
    }

    if(lang !== i18n.locale)
        i18n.locale = lang

    // transmettre l'origin si il y a, en cas de nouvelle inscription
    let nextParams = {}
    if(!hasQueryParams(to) && hasQueryParams(from) && from.query.origin){
        nextParams.name = to.name
        nextParams.query = {
            origin: from.query.origin
        }
        nextParams.params = {
            lang: to.params.lang
        }
        next(nextParams)
    } else {
        next()
  }
})

Vue.use(VuePlausible, {
  domain: process.env.VUE_APP_Plausible_Data_Domain,
  trackLocalhost: true,
  apiHost: "https://webstats.ownmailist.net",
  enableAutoPageviews: true
})

Vue.use(Meta)

new Vue({
    render: h => h(App),
    router,
    i18n,
    mounted(){
        new WOW().init();
    }
}).$mount('#app')
