<template>
  <div class="main-container">
    <router-view></router-view>
    <Header />

    <div
        v-if="partner"
        :style="bgImg"
        id="first-element"
    >
      <div class="container_f1 container">
        <div class="row justify-content-center mb-4 wow move-up">
          <img
            :src="partner.illustration"
            :alt="partner.key"
            class="partner-illustration"
          >
        </div>
        <div class="row justify-content-center mb-3">
          <a :href="partner.link" target="_blank">
            <h1 class="mailvio wow move-up">
              {{ $t('pages.partners.partners.'+partner.key+'.name') }}
            </h1>
          </a>
        </div>
        <div
          v-if="$route.params.partner == 'mailvio'"
          class="row justify-content-center mb-3"
        >
          <div class="mb-3">
            <p class="wow move-up font-weight-light text-center">
              {{ $t('pages.partners.partners.mailvio.p1') }}
            </p>
            <p class="wow move-up font-weight-light text-center">
              {{ $t('pages.partners.partners.mailvio.p2') }}
              <ul>
                <li v-for="i in [1,2,3,4,5,6,7]">
                  <i class="fa fa-check mailnjoy"/>
                  {{ $t('pages.partners.partners.mailvio.features.'+i) }}
                </li>
              </ul>
            </p>
            <p class="wow move-up font-weight-light text-center">
              {{ $t('pages.partners.partners.mailvio.p3') }}
            </p>
            <p class="wow move-up font-weight-light text-center">
              {{ $t('pages.partners.partners.mailvio.p4') }}
            </p>
          </div>
          <div class="tab-button wow move-up">
            <a
              :href="partner.link"
              target="_blank"
              class="ht-btn ht-btn-md mailnjoy-outline"
            >
              {{ $t("pages.partners.access") }} {{ $t('pages.partners.partners.'+partner.key+'.name') }}
            </a>
          </div>
        </div>
        <div
            v-else-if="$route.params.partner === 'bdd'"
            class="row justify-content-center mb-3"
        >
          <p class="wow move-up font-weight-light text-center">
            {{ $t('pages.partners.partners.bdd.p1') }}
            <ul>
              <li v-for="i in [1,2]">
                <i class="fa fa-check mailnjoy"/>
                {{ $t('pages.partners.partners.bdd.features.'+i) }}
              </li>
            </ul>
          </p>
          <p class="wow move-up font-weight-light text-center">
            {{ $t('pages.partners.partners.bdd.p2') }}
            <ul>
              <li v-for="i in [3,4,5,6]">
                <i class="fa fa-check mailnjoy"/>
                {{ $t('pages.partners.partners.bdd.features.'+i) }}
              </li>
            </ul>
          </p>
          <div class="tab-button wow move-up">
            <a
                :href="partner.link"
                target="_blank"
                class="ht-btn ht-btn-md mailnjoy-outline"
            >
              {{ $t("pages.partners.access") }} {{ $t('pages.partners.partners.'+partner.key+'.name') }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <FooterMain FooterStyle="bg_drak-footer" />
  </div>
</template>

<script>

import {defineComponent} from "vue";
import FooterMain from "@/components/check/FooterHome.vue";
import Header from "@/components/check/HeaderCheck.vue";
import Content from "@/components/check/DataTreatmentBody.vue";
import Hero from "@/components/check/HeroCheck.vue";

export default defineComponent({
  components: {Hero, Content, Header, FooterMain},
  data() {
    return {
      bgImg: {
        backgroundImage: `url(${require("../../assets/img/check/hero/shape.png")})`,
        backgroundRepeat: "no-repeat",
      },
      partners: [
        {
          key: 'mailvio',
          illustration: require('../../assets/img/partners/mailvio-illustration.png'),
          link: 'https://mailvio.com/'
        },
        {
          key: 'bdd',
          illustration: require('../../assets/img/partners/bdd-illustration.jpg'),
          link: 'https://blogdudigital.fr/formation-emailing/'
        }
      ]
    }
  },
  computed: {
    partner: function() {
      return this.partners.find((partner) => partner.key === this.$route.params.partner)
    }
  },
  created () {
    if (this.partner === undefined) {
      this.$router.push({ path: '/'+this.$i18n.locale+'/partners' })
    }
  },
  mounted () {
    //reset to top
    window.scrollTo(0,0);
  }
})
</script>

<style scoped>
  #first-element {
    padding-top: 140px;
  }
  .mailnjoy {
    color: #DEDC00;
  }
  .mailvio {
    color: rgb(168, 217, 102);
  }
  .partner-illustration {
    max-height: 400px;
    outline: solid 3px #95C11F;
    border-radius: 8px;
  }
</style>