<template>
  <div id="developer-content">
    <div class="container fluid">
      <div class="row align-items-center">
        <div class="col-lg-7">
          <div class="section-title text-left section-space--mb_20">
            <h2 class="font-weight--bold mb-15 mailnjoy wow move-up">
              {{ $t("components.developerContent.title") }}
            </h2>
            <p class="section-text_left wow move-up font-weight-light">
              {{ $t("components.developerContent.intro") }}
            </p>
            <h5 class="section-text_left mailnjoy wow move-up font-weight-light section-space--mt_30">
              {{ $t("components.developerContent.subtitle") }}
            </h5>
            <ul class="check-list green-check-list mt-3 wow move-up">
              <li class="list-item">
                <b>{{ $t("components.developerContent.item1.title") }}</b><br/>
                {{ $t("components.developerContent.item1.content") }}
              </li>
              <li class="list-item">
                <b>{{ $t("components.developerContent.item2.title") }}</b><br/>
                {{ $t("components.developerContent.item2.content") }}
              </li>
            </ul>
            <div
                class="mt-3 wow move-up"
                v-html="$t('components.developerContent.info1',['<a href=\''+developerUrl+'\' target=\'_blank\' class=\'surline\'><b>','</b></a>,'])+
                $t('components.developerContent.info2',['<a href=\'https://github.com/mailnjoy/check-api\' target=\'_blank\' class=\'surline\'><b>','</b></a>'])"
            >
            </div>
          </div>
        </div>
        <div class="col-lg-5 text-center mt-auto mb-auto">
          <div class="features_image-wrap section-space--mb_40">
            <div class="image wow move-up">
              <img class="img-fluid align-middle" src="../../assets/img/check/illustration/developer.png"
                   alt="Développeur">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GreenContent',
  computed: {
    developerUrl: function () {
      return process.env.VUE_APP_Developer_Portail_Url
    }
  }
}
</script>
<style type="text/css">
#developer-content {
  background-image: none;
  padding-top: 160px;
  width: 100%
}

a.surline {
  color: #95C11F;
}

a:hover.surline {
  text-decoration: underline;
  color: #95C11F;
}
</style>
