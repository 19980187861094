<template>
  <b-navbar type="light" toggleable="xl" fixed="top" v-b-scrollspy:nav-scroller class="header-area"
            :class="{'is-sticky': scrolled}">
    <div class="container-fluid container-fluid--cp-150">
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>
      <b-navbar-brand class="navbar-brand mailnjoy" :to="'/'+$i18n.locale+'/'"><img src="/check/check.png" alt="logo">
      </b-navbar-brand>
      <b-collapse class="default-nav justify-content-center" is-nav id="nav_collapse">
        <b-navbar-nav class="navbar-nav main-menu">
          <b-nav-item :to="'/'+$i18n.locale+'/'"><span>{{ $t("components.headerCheck.home") }}</span></b-nav-item>
          <b-nav-item :to="'/'+$i18n.locale+'/validation'"><span>{{ $t("components.headerCheck.validation") }}</span>
          </b-nav-item>
          <b-nav-item :to="'/'+$i18n.locale+'/pricing'"><span>{{ $t("components.headerCheck.pricing") }}</span>
          </b-nav-item>
          <b-nav-item :to="'/'+$i18n.locale+'/team'"><span>{{ $t("components.headerCheck.team") }}</span></b-nav-item>
          <b-nav-item to=""><span></span></b-nav-item>
          <b-nav-item :href="signupLink"><span>{{ $t("components.headerCheck.signup") }}</span></b-nav-item>
          <b-nav-item :href="appUrl"><span>{{ $t("components.headerCheck.signin") }}</span></b-nav-item>
          <b-nav-item-dropdown right>
            <template #button-content>
              <i class="fa fa-language" style="font-size: large"></i>
              {{ $i18n.locale.toUpperCase()}}

            </template>
            <b-dropdown-item v-for="lang in languages" @click="changeLanguage(lang)">{{
                lang.toUpperCase()
              }}
            </b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </div>
  </b-navbar>
</template>

<script>
import languages from '@/i18n/supportedLanguages'

export default {
  name: 'HeaderSoftware',
  data() {
    return {
      load: false,
      limitPosition: 200,
      scrolled: false,
      lastPosition: 500,
      languages: languages
    }
  },
  computed: {
    appUrl: function () {
      if (this.$route.query.origin) {
        return process.env.VUE_APP_Check_App_Root + '?origin=' + this.$route.query.origin
      } else {
        return process.env.VUE_APP_Check_App_Root
      }
    },
    signupLink: function () {
      if (this.$route.query.origin) {
        return process.env.VUE_APP_Signin_Link + '?origin=' + this.$route.query.origin
      } else {
        return process.env.VUE_APP_Signin_Link
      }
    }
  },
  mounted() {
    (function () {
      scrollTo();
    })();

    function scrollTo() {
      const links = document.querySelectorAll('.scroll > a');
      links.forEach(each => (each.onclick = scrollAnchors));
    }

    function scrollAnchors(e, respond = null) {
      const distanceToTop = el => Math.floor(el.getBoundingClientRect().top);
      e.preventDefault();
      var targetID = (respond) ? respond.getAttribute('href') : this.getAttribute('href');
      const targetAnchor = document.querySelector(targetID);
      if (!targetAnchor) return;
      const originalTop = distanceToTop(targetAnchor);
      window.scrollBy({top: originalTop, left: 0, behavior: 'smooth'});
      const checkIfDone = setInterval(function () {
        const atBottom = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 190;
        if (distanceToTop(targetAnchor) === 0 || atBottom) {
          targetAnchor.tabIndex = '-1';
          targetAnchor.focus();
          clearInterval(checkIfDone);
        }
      }, 800);
    }
  },
  methods: {
    // sticky menu script
    handleScroll() {
      if (this.lastPosition < window.scrollY && this.limitPosition < window.scrollY) {
        this.scrolled = true;
        // move up!
      }
      if (this.lastPosition > window.scrollY) {
        this.scrolled = true;
        // move down
      }
      this.lastPosition = window.scrollY;
      this.scrolled = window.scrollY > 50;
    },

    // offcanvas searchbox
    toggleClass(addRemoveClass, className) {
      const el = document.querySelector('#search-overlay');
      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    changeLanguage(lang) {
      if (languages.includes(lang)) {
        this.$i18n.locale = lang
        // create cookie
        const d = new Date();
        d.setTime(d.getTime() + (400 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = process.env.VUE_APP_Lang_cookie_name + "=" + lang + ";" + expires + ";domain=" +
            process.env.VUE_APP_domain_cookie + ";path=/";
        //route
        console.log(this.$route)
        history.pushState(
            {},
            null,
            '/check/' + lang + this.$route.path.substring(3)
        )
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
}
</script>
<style>
.navbar-brand.mailnjoy img {
  width: auto;
  height: 120px;
}

.ht-btn.btn-mailnjoy {
}

.ht-btn--outline.btn-mailnjoy {
  border-color: #95C11F;
  color: #95C11F;
  background: transparent;
}

.ht-btn--outline.btn-mailnjoy:hover {
  background-image: linear-gradient(218deg, #D9D418 0, #DEDC00 25%, #62D918 100%);
  color: white;
}

.is-sticky img {
  height: 75px !important;
}

.navbar-light .main-menu li .nav-link {
  padding: 5px 26px !important;
}
</style>