<template>
  <div class="hero-software hero-swiper-btn" id="home">
    <div class="container_f1 container">
      <div class="row hero-item justify-content-center mb-40">
        <div class="col-lg-1"></div>
        <div class="col-lg-5 text-center px-5">
          <div class="">
            <h1 class="h2 hero-content-title mailnjoy wow move-up">
              {{ $t("components.heroCheck.title") }}
            </h1>
            <h2 class="h5 hero-content-subtitle wow move-up"
                v-html="$t('components.heroCheck.subtitle', ['<b>','</b>'])"></h2>
            <a :href="signupLink" class="ht-btn ht-btn-md mailnjoy wow move-up" target="_blank">
              <span class="btn-iconZ ml-0 mr-2"><i class="fa fa-vial"></i></span> {{
                $t("components.heroCheck.action")
              }}
            </a>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="">
            <img src="../../assets/img/check/hero/thumb-2-small.png" class="img-fluid wow move-up" alt="hero thumb">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        speed: 1500,
        loop: true,
        effect: 'fade',
        autoplay: false,
        spaceBetween: 30
      }
    }
  },
  computed: {
    signupLink: function () {
      if (this.$route.query.origin) {
        return process.env.VUE_APP_Signin_Link + '?origin=' + this.$route.query.origin
      } else {
        return process.env.VUE_APP_Signin_Link
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variabls.scss';
@import '../../assets/scss/elements/hero-software.scss';

#home .container {
  max-width: none;
}

.hero-item {
  padding-top: 160px;
}

.hero-content-title.mailnjoy {
  color: #DEDC00;
}

.btn.btn--mailnjoy {
  background-color: #95C11F;
}

.first-subtitle {
  color: #7e7e7e;
  font-weight: 400;
  padding-top: 15px;
  padding-bottom: 10px;
}

.ht-btn.mailnjoy {
  background-image: linear-gradient(218deg, #95C11F 0, #DEDC00 50%, #95C11F 100%);
}

.second-subtitle {
  color: #7e7e7e;
  font-weight: 400;
  padding-top: 0;
  padding-bottom: 10px;
}
</style>
