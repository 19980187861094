<template>
  <div class="main-container">
    <router-view></router-view>
    <!-- Navbar section -->
    <Header />

    <Content :style="bgImg"/>

    <!-- Footer -->
    <FooterMain FooterStyle="bg_drak-footer" />
  </div>
</template>
  
<script>  
  import Header from "../../components/check/HeaderCheck"
  import Content from "../../components/check/CGUBody"
  import FooterMain from '../../components/check/FooterHome'

  export default {
    name: "cgu",
    components: {
      Header,
      Content,
      FooterMain
    },
    data() {
      return {
        bgImg: {
          backgroundImage: `url(${require("../../assets/img/check/hero/shape.png")})`,
          backgroundRepeat: "no-repeat",
        },
      }
    },
    mounted (){
      //reset to top
      window.scrollTo(0,0);
    }
  }
</script>