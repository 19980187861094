export default function getCurrency() {
    if(navigator.language.length < 5) {
        return navigator.language.substring(0,2) === 'fr' ? 'eur' : 'usd'
    }

    const country = navigator.language.substring(3).toLowerCase()
    const countriesUsingEuro = ['de', 'at', 'be', 'es', 'fi', 'fr', 'ie', 'it', 'lu', 'nl', 'pt', 'gr', 'si',
        'cy', 'mt', 'sk', 'ee', 'lv', 'lt', 'hr']

    if(country.length < 2) {}

    return countriesUsingEuro.includes(country) ? 'eur' : 'usd'
}