<template>
  <div id="cgu-body">
    <div  v-html="cguBody"/>
    <h3 class="mt-5">{{ $t('components.cgu.otherVersions') }}</h3>
    <ul>
      <li v-for="version in allowedVersions">
        <a :href="'CGU?version='+version"> {{ $d(new Date(version)) }} </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {marked} from "marked";
export default {
  name: 'CGUBody',
  data() {
    const versions = ['2024-06-01', '2020-05-06']
    return {
      version: versions[0],
      allowedVersions: versions,
      content: {
        fr: {},
        en: {}
      }
    }
  },
  async created() {
    for (const version of this.allowedVersions) {
      const enContent = marked(await (await fetch(`/check/markdown/cgu/${version}_en.md`)).text())
      const frContent = marked(await (await fetch(`/check/markdown/cgu/${version}_fr.md`)).text())

      this.$set(this.content.en, version, enContent)
      this.$set(this.content.fr, version, frContent)
    }

    if(this.$route.query.version && this.allowedVersions.includes(this.$route.query.version)) {
      this.version = this.$route.query.version
    }
  },
  computed: {
    cguBody() {
      return this.content[this.$i18n.locale][this.version]
    }
  }
}
</script>

<style type="text/css">
#cgu-body {
  background-image: none;
  padding-top: 160px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%
}

#cgu-body > h2, h3, h4, h5 {
  color: #3c3c3b;
}
</style>
