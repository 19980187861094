<template>
  <div id="security-body">
    <div class="container fluid">
      <div class="row">
        <div class="col-lg-6 order-1 order-lg-1">
          <div class="section-title text-left section-space--mb_20">
            <h2 class="font-weight--bold mb-15 mailnjoy wow move-up">
              {{ $t("components.teamHistory.title") }}
            </h2>
            <p class="section-text_left wow move-up font-italic font-weight-light"
               v-html=" $t('components.teamHistory.part1',['<i>','</i>'])"/>
            <p class="section-text_left wow move-up font-italic font-weight-light">
              {{ $t("components.teamHistory.part2") }}
            </p>
            <p class="section-text_left wow move-up font-italic font-weight-light">
              {{ $t("components.teamHistory.part3") }}
            </p>
          </div>
        </div>
        <div class="col-lg-6 order-2 order-lg-2">
          <div class="features_image-wrap section-space--mb_40">
            <div class="image text-center wow move-up">
              <img class="img-fluid" src="../../assets/img/check/illustration/research_team.png" alt="serveur sécurisé">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  }
}
</script>