<template>
  <div class="row pricing-table-three d-flex align-items-stretch mt-4">
    <div v-for="pack in packs" :key="pack.title" v-if="pack.shortList || showAllPacks"
         class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 pricing-table wow move-up d-flex align-items-stretch">
      <div class="pricing-table__inner row">
        <div v-if="pack.discount > 0" class="pricing-table__feature-mark">
                       <span class="text-center">
                         -{{ pack.discount }}%
                       </span>
        </div>
        <div class="pricing-table__head col-12">
          <h5 class="pricing-table__title"><b>
            {{ $t("components.pricingOneShot.pack." + pack.title) }}
          </b></h5>
          <div class="pricing-table__image">
            <img :src="pack.image" class="img-fluid"
                 :alt="$t('components.pricingOneShot.pack.' + pack.title)">
          </div>

        </div>
        <div class="pricing-table__body col-12">
          <p v-if="pack.price.htBeforeReduction !== pack.price.ht">
            <small class="struck">{{
                $t("components.pricingOneShot.noTaxes", [$n(pack.price.htBeforeReduction, displaycurrency)])
              }}</small>
          </p>
          <p class="font-weight-bold">
            <h3>
              {{ $n(pack.price.htBeforeReduction, displaycurrency).replace(/\&nbsp;/g, ' ') }}
            </h3>
            <i class="text-no-wrap">{{ $t("components.pricingOneShot.noTaxesIndication") }}</i>
          </p>
          <p class="font-weight-light">
            <small>{{ $t("components.pricingOneShot.vat", [$n(pack.price.ttc, displaycurrency)]) }}</small>
          </p>
          <p>
            {{ $t("components.pricingOneShot.perThousandCredits", [$n(pack.price.perThousandCredits, displaycurrency)]) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PackDetails',
  props: {
    packs: Array,
    currency: String
  },
  data() {
    return {
      showAllPacks: true
    }
  },
  computed: {
    displaycurrency() {
      return this.currency === 'eur' ? 'eur' : 'usdSimple'
    }
  }
}
</script>