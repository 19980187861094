<template>
  <div class="main-container">
    <!-- Navbar section -->
    <Header />

    <Content :style="bgImg"/>
    
    <tfoot></tfoot>
    <!-- Footer -->
    <Footer FooterStyle="bg_drak-footer" />
  </div>
  
</template>
<script>  
  import Header from "../../components/check/HeaderCheck";
  import Content from '../../components/check/SecurityContent';
  import Footer from '../../components/check/FooterHome';

  export default {
    name: "security",
    components: {
      Header,
      Content,
      Footer
    },
    data() {
      return {
        bgImg: {
          backgroundImage: `url(${require("../../assets/img/check/hero/shape.png")})`,
          backgroundRepeat: "no-repeat",
        },
      }
    },
    mounted (){
      //reset to top
      window.scrollTo(0,0);
    }
  }
</script>