<template>
  <div
      id="data-treatment-body"
  >
    <div  v-html="dataTreatmentBody"/>
  </div>
</template>

<script>
import {marked} from "marked";
export default {
  name: 'DataTreatmentBody',
  data() {
    return {
      content: {
        fr: "",
        en: ""
      }
    }
  },
  async created() {
    this.content.en = marked(await (await fetch(`/check/markdown/data/2020-05-06_en.md`)).text())
    this.content.fr = marked(await (await fetch(`/check/markdown/data/2020-05-06_fr.md`)).text())
  },
  computed: {
    dataTreatmentBody() {
      return this.content[this.$i18n.locale]
    }
  }
}
</script>

<style>
#data-treatment-body {
  background-image: none;
  padding-top: 160px;
  padding-right: 30px;
  padding-left: 30px;
  width: 100%
}

#data-treatment-body > h2, h3, h4, h5 {
  color: #3c3c3b;
}
</style>
