<template>
  <div id="mail-anatomy" class="container-fluid">
    <div class="row mb-2">
      <div class="col-lg-8 text-center order-2 order-lg-1">
        <div class="pl-lg-5 mb-4">
          <h6 class="content-subtitle mb-2 wow move-up">
            {{ $t("components.mailAnatomy.intro.title") }} :
          </h6>
          <h2 class="mailnjoy my-4 text-break wow move-up">
            xcqtykzwzxvgetdfpkf
            <wbr>
            @yahou.fr
          </h2>
          <h5 class="mb-3 wow move-up">
            <small class="text-muted">
              {{ $t("components.mailAnatomy.intro.part1") }}
            </small>
          </h5>
          <div class="wow move-up">
            <h6 class="content-subtitle mb-3">
              {{ $t("components.mailAnatomy.intro.part2") }}
              .</h6>
            <h6 class="content-subtitle"
                v-html="$t('components.mailAnatomy.intro.part3', ['<b class=\'mailnjoy\'>','</b>'])">
            </h6>
            <h6 class="content-subtitle"
                v-html="$t('components.mailAnatomy.intro.part4', ['<b class=\'mailnjoy\'>','</b>'])"></h6>
          </div>
        </div>
      </div>
      <div class="col-lg-4 text-center text-lg-left order-1 order-lg-2 wow move-up">
        <img id="analysis-img" class="img-fluid" src="../../assets/img/check/illustration/mail_analysis.png">
      </div>
    </div>
    <CurveShape class="no-animation remove-row-padding">
      <template v-slot:bgcolor>
        <path style="fill: #f8fbef;
                stroke-miterlimit:10;"
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                    S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                    S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>
    <div class="row">
      <div id="mail-analysis" class="col-12">
        <div class="tabs-wrapper section-space--pb_60 col-12">
          <div class="container">
            <div class="row">
              <div class="col-12 mt-2 mb-2">
                <h2 class="mailnjoy-grey mt-2 mb-4 wow move-up">
                  {{ $t("components.mailAnatomy.slider.title") }}
                </h2>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7 wow move-up">
                <slick ref="slick1" class="slick1 tab-slider-nav mailnjoy-slider"
                       :options="slickOptions">
                  <div class="tab-slider-nav--list wow move-up">
                    <i class="ht-slider-tab-icon fal fa-dice"></i>
                    <h5>{{ $t("components.mailAnatomy.slider.random.title") }}</h5>
                    <p>{{ $t("components.mailAnatomy.slider.random.content") }}</p>
                  </div>
                  <div class="tab-slider-nav--list wow move-up">
                    <i class="ht-slider-tab-icon fal fa-spider-web"></i>
                    <h5>{{ $t("components.mailAnatomy.slider.domain.title") }}</h5>
                    <p>{{ $t("components.mailAnatomy.slider.domain.content") }}</p>
                  </div>
                  <div class="tab-slider-nav--list wow move-up">
                    <i class="ht-slider-tab-icon fal fa-filter"></i>
                    <h5>{{ $t("components.mailAnatomy.slider.catchall.title") }}</h5>
                    <p>{{ $t("components.mailAnatomy.slider.catchall.content") }}</p>
                  </div>
                </slick>
              </div>
              <div class="col-lg-5 wow move-up">
                <slick ref="slick2" class="slick2" :options="slickOptions2">
                  <div class="pro-large-img img-zoom wow move-up">
                    <img class="img-fluid" src="../../assets/img/check/illustration/email_generation.png"
                         alt="product-details"/>
                  </div>
                  <div class="pro-large-img img-zoom wow move-up">
                    <img class="img-fluid" src="../../assets/img/check/illustration/bad_maileur.png"
                         alt="product-details"/>
                  </div>
                  <div class="pro-large-img img-zoom wow move-up">
                    <img class="img-fluid" src="../../assets/img/check/illustration/black_mail_hole.png"
                         alt="product-details"/>
                  </div>
                </slick>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CurveShape id="bottom-curve" class="curve-bottom no-animation remove-row-padding">
      <template v-slot:bgcolor>
        <path style="fill: #f8fbef;
            stroke-miterlimit:10;"
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>
  </div>
</template>
<script>
import Slick from 'vue-slick';
import CurveShape from '../CurveShape'

export default {
  name: 'TabTwo',
  components: {
    Slick,
    CurveShape
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 3,
        focusOnSelect: true,
        vertical: true,
        asNavFor: '.slick2',
        autoplay: true,
        autoplaySpeed: 4000
      },
      slickOptions2: {
        arrows: false,
        fade: true,
        dots: true,
        asNavFor: '.slick1',
        autoplay: true,
        autoplaySpeed: 4000
      }
    }
  },
  mounted: function () {
    let slick1 = this.$refs.slick1
    let slick2 = this.$refs.slick2
    window.addEventListener('resize', function () {
      slick1.reSlick();
      slick2.reSlick();
    })
  }
};
</script>
<style type="text/css">
#mail-anatomy {
  padding-top: 160px;
  width: 100%
}

.content-subtitle {
  color: #7e7e7e;
  font-weight: 400;
}

#mail-analysis {
  background-color: #f8fbef;
}

.mailnjoy-grey {
  color: #7e7e7e;
}

#analysis-img {
  max-height: 300px;
}

/* Slicker personnalisé*/
.tab-slider-nav.mailnjoy-slider .slick-current .tab-slider-nav--list .ht-slider-tab-icon {
  background-image: linear-gradient(-225deg, #DEDC00 0%, #95C11F 100%);
}

.tab-slider-nav.mailnjoy-slider h5 {
  color: #95C11F;
}

.tab-slider-nav.mailnjoy-slider .ht-slider-tab-icon {
  color: #7e7e7e;
}

.tab-slider-nav.mailnjoy-slider p {
  color: #7e7e7e;
}

.tab-slider-nav.mailnjoy-slider .tab-slider-nav--list {
  margin-bottom: 10px;
}

.slick-dots li button {
  font-size: 16px;
  background-color: #95C11F;
  opacity: 0.7;
}

.slick-dots li.slick-active button {
  color: red;
  opacity: 1;
}

.tab-slider-nav.mailnjoy-slider .slick-list.draggable, .tab-slider-nav.mailnjoy-slider .slick-track {
  height: auto !important;
}

.tab-slider-nav.mailnjoy-slider p {
  font-size: inherit;
}

/* Vagues statiques */
.curve-shape.no-animation svg {
  animation: none;
}

.curve-shape.remove-row-padding {
  margin-right: -15px;
  margin-left: -15px;
}
</style>