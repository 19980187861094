
<template>
  <div class="main-container">
    <router-view></router-view>
    <Header />

    <div
      :style="bgImg"
      id="first-element"
    >
      <div class="container_f1 container">
        <div class="row justify-content-center mb-2">
          <h1 class="mailnjoy wow move-up">
            {{ $t("pages.partners.title") }}
          </h1>
        </div>
        <div class="row justify-content-center mb-3">
          <p class="wow move-up font-weight-light mb-4 text-center">
            {{ $t("pages.partners.subtitle") }}
          </p>
        </div>
        <div class="row justify-content-center">
          <b-card
              v-for="partner in partners" :key="partner.key"
              :img-src="partner.logo"
              :img-alt="partner.key"
              img-top
              class="col-11 col-sm-10 col-md-6 col-lg-4 wow move-up mx-2"
          >
            <b-card-text v-html="$t('pages.partners.partners.'+partner.key+'.description')"/>
            <div class="text-center">
              <div class="tab-button wow move-up">
                <router-link :to="'/'+$i18n.locale+'/partner/'+partner.key" class="ht-btn ht-btn-md mailnjoy-outline">
                  {{ $t("pages.partners.discover") }} {{ $t('pages.partners.partners.'+partner.key+'.name') }}
                </router-link>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <FooterMain FooterStyle="bg_drak-footer" />
  </div>
</template>

<script>

import {defineComponent} from "vue";
import FooterMain from "@/components/check/FooterHome.vue";
import Header from "@/components/check/HeaderCheck.vue";
import Content from "@/components/check/DataTreatmentBody.vue";
import Hero from "@/components/check/HeroCheck.vue";

export default defineComponent({
  components: {Hero, Content, Header, FooterMain},
  data() {
    return {
      bgImg: {
        backgroundImage: `url(${require("../../assets/img/check/hero/shape.png")})`,
        backgroundRepeat: "no-repeat",
      },
      partners: [
        {
          logo: require('../../assets/img/partners/mailvio-logo.png'),
          key: 'mailvio'
        },{
          logo: require('../../assets/img/partners/bdd-logo.png'),
          key: 'bdd'
        }
      ]
    }
  },
  mounted () {
    //reset to top
    window.scrollTo(0,0);
  }
})
</script>

<style scoped>
  #first-element {
    padding-top: 120px;
  }

  .mailnjoy {
    color: #DEDC00;
  }
</style>