<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="section-title text-center section-space--mb_30 wow move-up">
          <h2 class="font-weight--light mb-15 mailnjoy">
            {{ $t("components.validationDetail.title") }}
          </h2>
          <span>
            {{ $t("components.validationDetail.subtitle") }}
          </span>
        </div>
      </div>
      <div class="col-12">
        <b-tabs class="ht-tab-menu mailnjoy wow move-up">
          <b-tab :title="$t('components.validationDetail.standardValidation')" active>
            <div class="row justify-content-md-center mt-5">
              <div class="col-md-4 text-center my-auto">
                <img :src="classicImage.effective" id="actual-illustration" class="img-fluid"/>
              </div>
              <div class="col-md-7">
                <ul class="">
                  <li v-for="feature in dataClassicValidation" :key="feature.id"
                      :class="feature.additionnalClasses + ' feature-validation hoverable'">
                    <div class="detail-container row">
                      <div class="icon col-2 text-right">
                        <i :class="feature.icon"></i>
                      </div>
                      <div class="content col-10">
                        <div class="row ">
                          <div class="title col-12">
                            <b>{{ feature.title }}</b>
                          </div>
                          <div v-html="feature.text" class="body col-12"></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
          <b-tab :title="$t('components.validationDetail.detailedValidation')">
            <div class="row justify-content-md-center mt-5">
              <div class="col-md-4 text-center my-auto">
                <img :src="deepImage.effective" id="deep-illustration" class="img-fluid align-middle"></img>
              </div>
              <div class="col-md-7">
                <ul class="">
                  <li v-for="feature in dataDeepValidation" :key="feature.id" class="feature-validation">
                    <div :class="feature.additionnalClasses + ' detail-container row hoverable'">
                      <div class="icon col-2 text-right">
                        <i :class="feature.icon"></i>
                      </div>
                      <div class="content col-10">
                        <div class="row ">
                          <div class="title col-12">
                            <b :class="feature.titleClasses">{{ feature.title }}</b>
                          </div>
                          <div v-html="feature.text" class="body col-12"></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ValidationDetail',
  data() {
    let classic = []
    let deep = []
    const categoriesClassic = [
      {
        'name': 'generated',
        'icon': 'fal fa-dice',
        'classClassic': '',
        'classDeep': ''
      }, {
        'name': 'disposable',
        'icon': 'fal fa-trash',
        'classClassic': 'ml-md-perso-1',
        'classDeep': 'ml-md-perso-1'
      }, {
        'name': 'role',
        'icon': 'fal fa-user-tie',
        'classClassic': 'ml-md-perso-2',
        'classDeep': 'ml-md-perso-2'
      }, {
        'name': 'spamtrap',
        'icon': 'fal fa-spider-black-widow',
        'classClassic': 'ml-md-perso-3',
        'classDeep': 'ml-md-perso-3'
      }, {
        'name': 'smtp',
        'icon': 'fal fa-server',
        'classClassic': 'ml-md-perso-3',
        'classDeep': 'ml-md-perso-4'
      }, {
        'name': 'alias',
        'icon': 'fal fa-directions',
        'classClassic': 'ml-md-perso-2',
        'classDeep': 'ml-md-perso-5'
      }, {
        'name': 'categorisation',
        'icon': 'fal fa-briefcase',
        'classClassic': 'ml-md-perso-1',
        'classDeep': 'ml-md-perso-6'
      }, {
        'name': 'catchall',
        'icon': 'fal fa-filter',
        'classClassic': '',
        'classDeep': 'ml-md-perso-6'
      }]
    const categoriesDeep = [
      {
        'name': 'leak',
        'icon': 'fal fa-skull',
        'class': 'ml-md-perso-5 light-mailnjoy-bg'
      }, {
        'name': 'social',
        'icon': 'fal fa-share-alt',
        'class': 'ml-md-perso-4 light-mailnjoy-bg'
      }, {
        'name': 'shop',
        'icon': 'fal fa-shopping-cart',
        'class': 'ml-md-perso-3 light-mailnjoy-bg'
      }, {
        'name': 'blacklist',
        'icon': 'fal fa-times',
        'class': 'ml-md-perso-3 light-mailnjoy-bg'
      }, {
        'name': 'blackdomain',
        'icon': 'fal fa-times-circle',
        'class': 'ml-md-perso-2 light-mailnjoy-bg'
      }, {
        'name': 'suspect',
        'icon': 'fal fa-question-circle',
        'class': 'ml-md-perso-1 light-mailnjoy-bg'
      }, {
        'name': 'young',
        'icon': 'fal fa-baby-carriage',
        'class': 'light-mailnjoy-bg'
      }]

    for (const i in categoriesClassic) {
      classic.push({
        id: i,
        title: this.$t('components.validationDetail.' + categoriesClassic[i].name + '.title'),
        text: this.$t('components.validationDetail.' + categoriesClassic[i].name + '.text'),
        icon: categoriesClassic[i].icon,
        additionnalClasses: categoriesClassic[i].classClassic
      })
      deep.push({
        id: i,
        title: this.$t('components.validationDetail.' + categoriesClassic[i].name + '.title'),
        text: this.$t('components.validationDetail.' + categoriesClassic[i].name + '.text'),
        icon: categoriesClassic[i].icon,
        additionnalClasses: categoriesClassic[i].classDeep
      })
    }

    const inc = categoriesClassic.length
    for (const i in categoriesDeep) {
      deep.push({
        id: i + inc,
        title: this.$t('components.validationDetail.' + categoriesDeep[i].name + '.title'),
        text: this.$t('components.validationDetail.' + categoriesDeep[i].name + '.text'),
        icon: categoriesDeep[i].icon,
        titleClasses: 'shimmer',
        additionnalClasses: categoriesDeep[i].class
      })
    }

    return {
      classicImage: {
        effective: '',
        large: require("../../assets/img/check/illustration/validation_classic.png"),
        small: require("../../assets/img/check/illustration/validation_classic_square.png"),
      },
      deepImage: {
        effective: '',
        large: require("../../assets/img/check/illustration/validation_deep.png"),
        small: require("../../assets/img/check/illustration/validation_deep_square.png"),
      },
      windowWidth: 0,
      dataClassicValidation: classic,
      dataDeepValidation: deep
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth >= 768) {
        this.classicImage.effective = this.classicImage.large
        this.deepImage.effective = this.deepImage.large
      } else {
        this.classicImage.effective = this.classicImage.small
        this.deepImage.effective = this.deepImage.small
      }
    }
  }
}
</script>
<style type="text/css">
#actual-illustration {
  max-height: 656px;
}

#deep-illustration {
  max-height: 1288px;
}

.detail-container .header {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.detail-container .icon {
  border-radius: 8px;
  font-size: 36px;
  color: #95c11f;
}

.ht-tab-menu.mailnjoy .nav-tabs li a.active {
  background-color: #95c11f;
}

/* margin perso */
@media (min-width: 768px) {
  .ml-md-perso-1 {
    margin-left: 0.75rem;
  }

  .ml-md-perso-2 {
    margin-left: 1.5rem;
  }

  .ml-md-perso-3 {
    margin-left: 2.25rem;
  }

  .ml-md-perso-4 {
    margin-left: 3rem;
  }

  .ml-md-perso-5 {
    margin-left: 3.75rem;
  }

  .ml-md-perso-6 {
    margin-left: 4.5rem;
  }

  .ml-md-perso-7 {
    margin-left: 5.25rem;
  }
}

/* hoverable features */
.hoverable {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.hoverable:hover {
  transform: translateY(-5px);
  box-shadow: 0 18px 40px 5px rgba(51, 51, 51, 0.1);
}

.feature-validation {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.hoverable:hover i.fal {
  color: #dedc00;
  transition: color 0.3s;
}

/* effet brillant */
.light-mailnjoy-bg {
  background-color: #f8fbef;
}

.shimmer {
  color: rgba(126, 126, 126, 0.1);
  background: -webkit-gradient(linear, left top, right top, from(#95c11f), to(#95c11f), color-stop(0.05, #fff));
  background: -moz-gradient(linear, left top, right top, from(#95c11f), to(#95c11f), color-stop(0.05, #fff));
  background: gradient(linear, left top, right top, from(#95c11f), to(#95c11f), color-stop(0.05, #fff));
  -webkit-background-size: 50px 100%;
  -moz-background-size: 50px 100%;
  background-size: 50px 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-animation-name: shimmer;
  -moz-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-color: #7e7e7e;
}

@-moz-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: bottom right;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: bottom right;
  }
}

@-o-keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: bottom right;
  }
}

@keyframes shimmer {
  0% {
    background-position: top left;
  }
  100% {
    background-position: bottom right;
  }
}
</style>