<template>
  <div class="check-section container">
    <div id="quality" class="row align-items-center">
      <div class="col-lg-1"></div>
      <div class="col-lg-5">
        <div class="feature-icon-list__media wow move-up">
          <img src="../../assets/img/check/illustration/microscope.png" id="microscope" class="img-fluid"
               :alt="$t('components.qualityPriceSection.microscopeAlt')">
        </div>
      </div>
      <div class="col-lg-5 align-middle">
        <div class="feature-icon-list__content">
          <div class="requirements-list">
            <h2 class="requirements-list__title mb-15 wow move-up mailnjoy">
              {{ $t("components.qualityPriceSection.part1.title") }}
            </h2>
            <ul class="check-list green-check-list section-space--mb_30 wow move-up">
              <li class="list-item" v-for="i in [1,2,3]">
                {{ $t("components.qualityPriceSection.part1.point" + i) }}
              </li>
            </ul>
            <div class="text-center">
              <div class="tab-button wow move-up">
                <router-link :to="'/'+$i18n.locale+'/validation'" class="ht-btn ht-btn-md mailnjoy-outline">
                  {{ $t("components.qualityPriceSection.part1.action") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="simplicity" class="row align-items-center">
      <div class="col-lg-1"></div>
      <div class="col-lg-5 order-2 order-xl-1 align-middle">
        <div class="requirements-list">
          <h2 class="requirements-list__title mb-15 wow move-up mailnjoy">
            {{ $t("components.qualityPriceSection.part2.title") }}
          </h2>
          <ul class="check-list green-check-list section-space--mb_30 wow move-up">
            <li class="list-item" v-for="i in [1,2]">
              {{ $t("components.qualityPriceSection.part2.point" + i) }}
            </li>
            <li class="list-item">
              {{ $t("components.qualityPriceSection.part2.point3") }}
              <router-link :to="'/'+$i18n.locale+'/developer'" class="textual-link">
                {{ $t("components.qualityPriceSection.part2.point3link") }}
              </router-link>
            </li>
          </ul>
          <div class="text-center">
            <div class="tab-button wow move-up">
              <router-link :to="'/'+$i18n.locale+'/validation/#result'" class="ht-btn ht-btn-md mailnjoy-outline">
                {{ $t("components.qualityPriceSection.part2.action") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 order-1 order-xl-2">
        <div class="feature-icon-list__media wow move-up">
          <img src="../../assets/img/check/illustration/slacking.png" id="slack-img" class="img-fluid"
               :alt="$t('components.qualityPriceSection.coinAlt')">
        </div>
      </div>
    </div>
    <div id="price" class="row align-items-center">
      <div class="col-lg-1"></div>
      <div class="col-lg-5">
        <div class="feature-icon-list__media wow move-up">
          <img :src="coinIllustrationPath" class="img-fluid"
               :alt="$t('components.qualityPriceSection.coinAlt')">
        </div>
      </div>
      <div class="col-lg-5">
        <div class="requirements-list">
          <h2 class="requirements-list__title mb-15 wow move-up mailnjoy">
            {{ $t("components.qualityPriceSection.part3.title") }}
          </h2>
          <ul class="check-list green-check-list section-space--mb_30 wow move-up">
            <li class="list-item">
              {{ $t("components.qualityPriceSection.part3.point1",[$n(startingCost, defaultCurrency)]) }}
            </li>
            <li class="list-item" v-for="i in [2,3]">
              {{ $t("components.qualityPriceSection.part3.point" + i) }}
            </li>
          </ul>
          <div class="text-center">
            <div class="tab-button wow move-up">
              <router-link :to="'/'+$i18n.locale+'/pricing'" class="ht-btn ht-btn-md mailnjoy-outline">
                {{ $t("components.qualityPriceSection.part3.action") }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- bloc de conclusion -->
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="section-title text-center section-space--mb_40">
          <h2 class="mailnjoy wow move-up title-padding-bottom"
              v-html="$t('components.qualityPriceSection.conclusion',['<br/>'])">
          </h2>
          <a :href="signupLink" target="_blank" class="ht-btn ht-btn-md mailnjoy">
            <span class="btn-iconZ ml-0 mr-2"><i class="fa fa-vial"></i></span>
            {{ $t("components.qualityPriceSection.action") }}
          </a>
        </div>
      </div>
    </div>
  </div>


</template>
<script>
import getCurrency from "@/utils/getCurrency";
export default {
  data() {
    return {
      startingCost: 0.0004,
      defaultCurrency: 'eur',
      coinIllustrationPath: '../../assets/img/check/illustration/piece_1_centime_travers.png'
    }

  },
  computed: {
    signupLink: function () {
      if (this.$route.query.origin) {
        return process.env.VUE_APP_Signin_Link + '?origin=' + this.$route.query.origin
      } else {
        return process.env.VUE_APP_Signin_Link
      }
    }
  },
  created() {
    this.defaultCurrency = getCurrency()
    this.startingCost = this.defaultCurrency === 'eur' ? 0.0004 : 0.0005
    const imgName = this.defaultCurrency === 'eur' ? 'piece_1_centime_travers' : 'penny-incline'
    this.coinIllustrationPath = require('@/assets/img/check/illustration/' + imgName + '.png')
  }
}
</script>
<style>
.check-section.container {
  max-width: none;
}

.requirements-list {
  margin-top: 20px;
  margin-bottom: 20px;
}

h2.mailnjoy {
  color: #DEDC00;
}

h5.mailnjoy {
  color: #95C11F;
}

.ht-btn.mailnjoy-outline {
  border-color: #95C11F;
  border-width: 2px;
  color: #95C11F;
  background-color: transparent;
  background-image: none;
}

.ht-btn.mailnjoy-outline:hover {
  background-color: #95C11F;
  /*background-image: linear-gradient(218deg, #95C11F 0, #DEDC00 50%);*/
  color: white;
}

.ht-btn.mailnjoy {
  background-image: linear-gradient(218deg, #95C11F 0, #DEDC00 50%, #95C11F 100%);
}

h2.title-padding-bottom {
  color: #95C11F;
  padding-bottom: 25px;
}

.hero-content.mailnjoy {
  width: 50%;
  padding-left: 5%;
}

.hero-item {
  height: auto !important;
}

.check-list .list-item {
  list-style-image: url("../../assets/img/check/logo/check_logo_mini.png");
  margin-left: 32px;
  padding-left: 0;
}

.check-list .list-item::before {
  content: none;
}

.textual-link, .textual-link:hover {
  color: #95C11F;
}

#slack-img {
  max-height: 500px;
}

@media only screen and (max-width: 991px) {
  #microscope {
    width: 450px;
  }
}
</style>