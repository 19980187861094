<template>
    <div id="app" class="h-100">
        <router-view></router-view>
    </div>    
</template>

<script>
  import languages from '@/i18n/supportedLanguages'
    export default {
      name: 'app',
      metaInfo () {
        let links = []
        links.push({
          rel: 'canonical',
          href: new URL(this.$router.resolve('/fr' + this.$route.path.substring(3)).href, window.location.origin).href,
        })
        for (const i in languages) {
          const lang = languages[i]
          console.log(lang)
          links.push({
            rel: 'alternate',
            href: new URL(this.$router.resolve('/' + lang + this.$route.path.substring(3)).href, window.location.origin).href,
            hreflang: lang
          })
        }
        return {
          title: this.$t('routes.titles.default.title'),
          htmlAttrs: {
            lang: this.$i18n.locale
          },
          link: links,
          meta: [
            { name: 'description', content: this.$t('routes.titles.default.description')}
          ]
        }
      }
    }
</script>
<style type="text/css">
  /* pas de scroll horizontal */
  router-view {
      max-width: 100%;
      width: 100%;
  }
  body *:not(i) {
    font-family: 'Lato' !important;
  }
  /* police un peu plus grosse par défaut */
  .main-container {
      font-size: 1.1rem;
  }
</style>