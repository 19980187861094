<template>
  <div>
    <CurveShape>
      <template v-slot:bgcolor>
        <path style="fill: #f8fbef; stroke-miterlimit:10;"
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>
    <div id="team-check" class="container">
      <div class="row ">
        <div class="col-lg-12">
          <div class="section-title text-center section-space--mb_60">
            <h2 class="font-weight--light mb-15 wow move-up mailnjoy">
              {{ $t("components.teamCheck.title") }}
            </h2>
            <span class="section-text wow move-up">
                          {{ $t("components.teamCheck.subtitle") }}
                        </span>
          </div>
        </div>
        <div class="col-lg-12 px-5">
          <div class="row justify-content-md-center mb-3 wow move-up">
            <div class="col-lg-6 col-xl-4" v-for="member in team" :key="member.name">
              <div class="ht-team-member text-center d-flex flex-column h-100">
                <div class="ht-team-member--inner d-flex flex-column h-100">
                  <div class="team-info text-center">
                    <img :src="member.image" class="img-fluid team-mailnjoy-portrait"/>
                    <h5 v-html="member.name" class="name"/>
                    <div v-html="member.position" class="position"></div>
                    <small v-html="member.title"/>
                  </div>
                  <div class="container mt-4 solid-rounded-icon ht-social-networks">
                    <div class="row mb-3" v-for="category in categories" :key="category.name">
                      <div class="col-2 text-right team-icon">
                        <a class="social-link hint--bounce hint--top  hint--primary"
                           :aria-label="$t('components.teamCheck.label.'+category.name)"><i
                            :class="'fas '+category.icon"></i></a>
                      </div>
                      <div class="col-10 my-auto text-left">
                        <span v-html="member[category.name]" class="text-left"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CurveShape id="bottom-curve" class="curve-bottom">
      <template v-slot:bgcolor>
        <path style="fill: #f8fbef; stroke-miterlimit:10;"
              d="M3360.5,97.739c-242,0-480-48.375-480-48.375
                        S2647.5,0.5,2400.5,0.5s-480,48.375-480,48.375s-238,48.864-480,48.864s-480-48.375-480-48.375S727.5,0.5,480.5,0.5
                        S0.5,48.875,0.5,48.875V108h1920h1920V48.875C3840.5,48.875,3602.5,97.739,3360.5,97.739z"/>
      </template>
    </CurveShape>
  </div>


</template>
<script>
import CurveShape from '../CurveShape'

export default {
  name: 'TeamCheck',
  components: {
    CurveShape
  },
  data() {
    const names = ["ciprian", "herve", "nicolas", "renaud", "sonya"]
    return {
      categories: [
        {
          "name": "career",
          "icon": "fa-file-certificate"
        }, {
          "name": "likes",
          "icon": "fa-heart"
        }, {
          "name": "dislikes",
          "icon": "fa-thumbs-down"
        }, {
          "name": "quote",
          "icon": "fa-quote-right"
        }, {
          "name": "emailing",
          "icon": "fa-envelope-open-text"
        }],
      team: [],
      names: names
    }
  },
  watch: {
    '$i18n.locale'() {
      this.generateTeamData()
    }
  },
  created() {
    this.generateTeamData()
  },
  methods: {
    generateTeamData() {
      this.team = []
      for (const i in this.names) {
        const name = this.names[i]
        this.team.push({
          image: require('../../assets/img/check/portrait/' + name + '_round.png'),
          name: this.$t('components.teamCheck.' + name + '.name'),
          position: this.$t('components.teamCheck.' + name + '.position'),
          title: this.$t('components.teamCheck.' + name + '.title'),
          career: this.$t('components.teamCheck.' + name + '.career'),
          likes: this.$t('components.teamCheck.' + name + '.likes'),
          dislikes: this.$t('components.teamCheck.' + name + '.dislikes'),
          quote: '<i>« ' + this.$t('components.teamCheck.' + name + '.quote') + ' »</i> ' + this.$t('components.teamCheck.' + name + '.quoteAuthor'),
          emailing: this.$t('components.teamCheck.' + name + '.emailing'),
        })
      }
    }
  }
}
</script>
<style type="text/css">
#team-check {
  background-color: #f8fbef !important;
  max-width: none;
}

.position {
  color: #95C11F !important;
  font-size: inherit !important;
}

.ht-team-member--inner {
  margin: 10px;
}

#bottom-curve {
  margin-top: -3px;
  margin-bottom: 0 !important;
}

img.team-mailnjoy-portrait {
  max-width: 200px;
  width: 100%;
}

.team-icon {
  min-width: 48px;
}

/* icones à nos couleurs */
.ht-social-networks.solid-rounded-icon .social-link:hover {
  background-color: #95C11F;
  border: 1px solid #95C11F;
}

.hint--top:before {
  border-top-color: #95C11F;
}

[class*="hint--"]:after {
  background: #95C11F;
}

/* listes */
.ht-social-networks.solid-rounded-icon a.social-link {
  line-height: 50px;
}
</style>
