<template>
  <div class="main-container">
    <router-view></router-view>
    <!-- Navbar section -->
    <Header />

    <!-- hero section -->
    <Hero :style="bgImg" />

    <!-- Content -->
    <QualityPrice />
    <Feature id="team"></Feature>

    <!-- Footer -->
    <FooterMain FooterStyle="bg_drak-footer" />
  </div>
  
</template>
<script>  
  import Header from "../../components/check/HeaderCheck";
  import Hero from "../../components/check/HeroCheck";
  import QualityPrice from "../../components/check/QualityPriceSection";
  import CurveShape from '../../components/CurveShape';
  import Feature from "../../components/check/FeatureCheck";
  import FooterMain from '../../components/check/FooterHome'

  export default {
    name: "app",
    components: {
      Header,
      Hero,
      QualityPrice,
      CurveShape,
      Feature,
      FooterMain
    },
    data() {
      return {
        bgImg: {
          backgroundImage: `url(${require("../../assets/img/check/hero/shape.png")})`,
          backgroundRepeat: "no-repeat",
        }
      }
    },
    mounted() {
      //reset to top
      window.scrollTo(0,0);
    }
  };
</script>