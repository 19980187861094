<template>
  <div class="main-container">
    <router-view></router-view>
    <!-- Navbar section -->
    <Header />

    <Anatomy />
    <!--<Anatomy :style="bgImg"></Anatomy>-->
    <Detail class="mt-5"></Detail>

    <Result id="result" class="mt-5"></Result>

    <!-- Footer -->
    <FooterMain FooterStyle="bg_drak-footer" />
  </div>
  
</template>
<script>  
  import Header from "../../components/check/HeaderCheck";
  import Anatomy from "../../components/check/MailAnatomy";
  import Detail from "../../components/check/ValidationDetail";
  import FooterMain from '../../components/check/FooterHome';
  import Result from '../../components/check/ResultDetails.vue';

  export default {
    name: "app",
    components: {
      Header,
      Anatomy,
      Detail,
      FooterMain,
      Result
    },
    data() {
        return {
            bgImg: {
                backgroundImage: `url(${require("../../assets/img/check/hero/shape.png")})`,
                backgroundRepeat: "no-repeat",
            },
        }
    },
    mounted (){
      //reset to top
      window.scrollTo(0,0);
    }
  };
</script>